export class Breakpoints {
  static XSmall = '(max-width: 599.98px)';
  static Small = '(min-width: 600px) and (max-width: 959.98px)';
  static Medium = '(min-width: 960px) and (max-width: 1279.98px)';
  static Large = '(min-width: 1280px) and (max-width: 1919.98px)';
  static XLarge = '(min-width: 1920px)';
  static Desktop = '(min-width: 960px)';
  static Mobile = '(max-width: 959.98px)';
}

export type Breakpoint = keyof Breakpoints;
export type StringBreakpoint =
  | 'XSmall'
  | 'Small'
  | 'Medium'
  | 'Large'
  | 'XLarge'
  | 'Desktop'
  | 'Mobile';
export type BreakpointValue = Breakpoints[Breakpoint];

import { ChangeDetectorRef, Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Breakpoint, BreakpointsHelper, StringBreakpoint } from '@shared/core/breakpoints';

@Directive({
  selector: '[engBreakpoints]',
})
@UntilDestroy()
export class EngBreakpointsDirective {
  private sub?: Subscription;

  @Input() set engBreakpoints(value: StringBreakpoint[]) {
    this.sub?.unsubscribe();
    this.sub = this.observer
      .observe(BreakpointsHelper.values(value as Breakpoint))
      .pipe(untilDestroyed(this))
      .subscribe((state) => {
        if (state.matches) {
          this.vcr.createEmbeddedView(this.tpl);
          this.cdr.markForCheck();
        } else {
          this.vcr.clear();
        }
      });
  }

  constructor(
    private tpl: TemplateRef<Record<string, never>>,
    private vcr: ViewContainerRef,
    private cdr: ChangeDetectorRef,
    private observer: BreakpointObserver,
  ) {}
}

import { ChangeDetectorRef, Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Breakpoint, BreakpointsHelper, StringBreakpoint } from '@shared/core/breakpoints';
import { CurrentUserState } from '@state/current-user/current-user.state';

@Directive({
  selector: '[engBreakpointsClass]',
  exportAs: 'engBreakpointsType',
})
@UntilDestroy()
export class EngBreakpointsClassDirective {
  isDesktop = false;
  private sub?: Subscription;
  private nameClass = 'Desktop';

  @Input() set engBreakpointsClass(value: StringBreakpoint[]) {
    this.sub?.unsubscribe();
    this.sub = this.observer
      .observe(BreakpointsHelper.values(value as Breakpoint))
      .pipe(untilDestroyed(this))
      .subscribe((state) => {
        if (state.matches) {
          this.isDesktop = true;
          this.r.addClass(this.el.nativeElement.parentNode, this.nameClass);
          this.cdr.markForCheck();
        } else {
          this.isDesktop = false;
          this.r.removeClass(this.el.nativeElement.parentNode, this.nameClass);
          this.cdr.markForCheck();
        }
        this.currentUserState.updateIsDesktop(this.isDesktop);
      });
  }

  @Input() set BreakPointsClass(value: string) {
    if (value) {
      this.nameClass = value;
    }
  }

  constructor(
    private r: Renderer2,
    private el: ElementRef,
    private cdr: ChangeDetectorRef,
    private observer: BreakpointObserver,
    private currentUserState: CurrentUserState,
  ) {}
}
